<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <h4>All Tickets</h4>
            <router-link
              to="/tickets/create"
              class="btn btn-icon icon-left btn-primary"
              ><i class="far fa-plus"></i> Create Ticket</router-link
            >
          </div>
          <div class="card-body">
            <a
              href="#"
              class="
                btn btn-primary btn-icon
                icon-left
                btn-lg btn-block
                mb-4
                d-md-none
              "
              data-toggle-slide="#ticket-items"
            >
              <i class="fas fa-list"></i> All Tickets
            </a>
            <div class="tickets" :class="[!ticket ? 'align-items-center' : '']">
              <div class="ticket-items" id="ticket-items">
                <div
                  class="ticket-item mb-3"
                  :class="[activeTicket == ticket.ticketId ? 'active' : '']"
                  v-for="(ticket, index) in tickets"
                  :key="index"
                  @click.prevent="loadTicket(ticket.ticketId)"
                >
                  <div class="ticket-title">
                    <h4>{{ ticket.title }}</h4>
                  </div>
                  <div class="ticket-desc mb-1">
                    <div>{{ ticket.ticketId }}</div>
                    <div class="bullet"></div>
                    <div>{{ ticket.created_at }}</div>
                  </div>
                  <div class="ticket-desc">
                    <div>Status: {{ ticket.status }}</div>
                    <div class="bullet"></div>
                    <div>Priority: {{ ticket.priority }}</div>
                  </div>
                </div>
              </div>
              <div class="ticket-content" v-if="ticket">
                <div class="ticket-header">
                  <div class="ticket-sender-picture img-shadow">
                    <img src="assets/img/avatar/avatar-5.png" alt="image" />
                  </div>
                  <div class="ticket-detail">
                    <div class="ticket-title">
                      <h4>{{ ticket.title }}</h4>
                    </div>
                    <div class="ticket-info">
                      <div class="font-weight-600">
                        Device ID: {{ ticket.unit_serial_number ? ticket.unit_serial_number : 'N/A' }}
                      </div>
                      <div class="bullet"></div>
                      <div class="text-primary font-weight-600">
                        Priority:
                        <div
                          class="badge"
                          :class="[
                            ticket.priority == 'high'
                              ? 'badge-danger'
                              : 'badge-warning',
                          ]"
                        >
                          {{ ticket.priority ? $globalFunc.capitalizeFirstLetter(ticket.priority) : 'N/A' }}
                        </div>
                      </div>
                    </div>
                    <div class="ticket-info">
                      <div class="font-weight-600">
                        Issue: {{ ticket.issue.title }}
                      </div>
                      <div class="bullet"></div>
                      <div class="text-primary font-weight-600">
                        Address:
                        {{
                          `${ticket.community}, ${ticket.lga}, ${ticket.state}`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ticket-description">
                  <p>
                    {{ ticket.description }}
                  </p>
                  <div class="form-group">
                    <label>Update status</label>
                    <select
                      class="form-control select2"
                      v-model="selectedStatus"
                      @change="updateStatus"
                    >
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                  <div class="ticket-divider"></div>
                  <div class="ticket-form" v-if="ticket.status == 'open'">
                    <form @submit.prevent="addComment()">
                      <div class="form-group">
                        <textarea
                          class="summernote form-control"
                          placeholder="Type a reply ..."
                          rows="5"
                          v-model="comment"
                          required
                        ></textarea>
                      </div>
                      <div class="form-group text-right">
                        <button
                          class="btn btn-primary btn-lg"
                          :class="{ 'btn-progress': loading }"
                          type="submit"
                        >
                          Comment
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <h2 class="section-title">Comments</h2>
                <div class="row mt-3" v-if="ticket.comments.length > 0">
                  <div class="col-12">
                    <div
                      class="activities"
                      v-for="(comment, index) in ticket.comments"
                      :key="index"
                    >
                      <div class="activity">
                        <div
                          class="
                            activity-icon
                            bg-primary
                            text-white
                            shadow-primary
                          "
                        >
                          <i class="fas fa-comment-alt"></i>
                        </div>
                        <div class="activity-detail">
                          <div class="mb-2">
                            <span class="text-job text-primary">{{
                              comment.created_at
                                ? $globalFunc.getDate(comment.created_at)
                                : "Just now"
                            }}</span>
                            <!-- <span class="bullet"></span>
                          <a class="text-job" href="#">View</a> -->
                          </div>
                          <p>{{ comment.comment }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-12">
                    <h6>No comment has been added yet</h6>
                  </div>
                </div>
              </div>
              <div class="ticket-content" v-else>
                <div class="ticket-description">
                  <h3 class="text-center mt-50">
                    Select a ticket to preview <br />
                    👈
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TicketHelpers from '@/functions/actions/TicketRequest'
import { mapState } from 'vuex';
export default {
  name: "Tickets",
  computed: {
    ...mapState(['loading'])
  },
  mounted() {
    this.fetchTickets();
    const ticketId = this.$route.query && this.$route.query.ticketId
    if (ticketId) {
      this.loadTicket(ticketId)
    }
  },
  data() {
    return {
      tickets: [],
      activeTicket: '',
      selectedStatus: '',
      comment: '',
      ticket: undefined
    };
  },
  methods: {
    async fetchTickets() {
      const response = await TicketHelpers.getTickets()
      if (response.status) {
        this.tickets = response.tickets
      } else {
        this.showAlert('Error occured', `${response.message}. Kindly reload the page`, 'error')
      }
    },
    async loadTicket(ticketId) {
      const response = await TicketHelpers.singleTicket(ticketId)
      if (response.status) {
        this.ticket = response.ticket
        this.activeTicket = ticketId
        this.selectedStatus = this.ticket.status
      } else {
        this.showAlert('Error occured', `${response.message}`, 'error')
      }
    },
    async updateStatus() {
      const response = await TicketHelpers.updateTicketStatus(this.activeTicket, this.selectedStatus)
      if (response.status) {
        this.ticket.status = this.selectedStatus
        this.tickets.find(ticket => ticket.ticketId == this.activeTicket).status = this.selectedStatus
      } else {
        this.showAlert('Error occured', `${response.message}`, 'error')
      }
    },
    async addComment() {
      const response = await TicketHelpers.addComment(this.activeTicket, this.comment)
      if (response.status) {
        this.ticket.comments = [{ comment: this.comment }, ...this.ticket.comments]
        this.comment = ''
      } else {
        this.showAlert('Error occured', `${response.message}`, 'error')
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      })
    }
  },
};
</script>
<style lang="">
</style>