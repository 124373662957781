import store from "../../store";
import router from "../../router";
import TicketService from "../../services/TicketService";

export default {
  getTickets() {
    store.dispatch("setLoading", true);
    const response = TicketService.getTickets()
      .then((result) => {
        const { status, data: { tickets } } = result.data
        if (status) {
          return { status: true, tickets };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  singleTicket(ticketId) {
    store.dispatch("setLoading", true);
    const response = TicketService.singleTicket(ticketId)
      .then((result) => {
        const { status, data: { ticket } } = result.data
        if (status) {
          return { status: true, ticket };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  updateTicketStatus(ticketId, status) {
    store.dispatch("setLoading", true);
    const response = TicketService.updateTicketStatus(ticketId, status)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  addComment(ticketId, comment) {
    store.dispatch("setLoading", true);
    const response = TicketService.addComment(ticketId, comment)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  createTicket(payload) {
    store.dispatch("setLoading", true);
    const response = TicketService.createTicket(payload)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
