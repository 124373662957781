import Api from "@/services/Api";

export default {
  getTickets() {
    return Api().get("/support/tickets");
  },
  singleTicket(ticketId) {
    return Api().get(`/support/tickets/${ticketId}`);
  },
  updateTicketStatus(ticketId, status) {
    return Api().put(`/support/tickets/${ticketId}/status`, { status });
  },
  addComment(ticketId, comment) {
    return Api().post(`/support/tickets/${ticketId}/comment`, { comment });
  },
  createTicket(credentials) {
    return Api().post("/support/tickets", credentials);
  },
};